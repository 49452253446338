import XIcon from '@duyank/icons/regular/X';
import { SerializedLayer, SerializedLayers, SerializedPage } from 'lidojs/design-core';
import { useEditor } from 'lidojs/design-editor';
import axios from 'src/utils/axiosInterceptorInstance';
import React, { FC, act, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useAsync } from 'react-use';
// import SheetIcon from 'src/assets/sheet-icon.png';
import SheetIcon from 'src/assets/sheet-logo.svg';
import toast from 'react-hot-toast';
import MagnifyingGlassIcon from '@duyank/icons/regular/MagnifyingGlass';
import { useMainContext } from 'src/context/MainContext';
import { convertHtmlToText } from 'src/utils/htmlParse';
import { Extras } from 'src/types';
import { isArray } from 'lodash';
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import { RiAccountCircleLine, RiGlobalLine } from 'react-icons/ri';
import { BsCopy, BsStars, BsThreeDotsVertical } from 'react-icons/bs';
import { VscTrash } from 'react-icons/vsc';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

interface Template {
  _id: string;
  createdAt: string;
  extras: Extras[];
  layers: SerializedLayers;
  sheetId: string;
  templateImage: string;
  templateName: string;
  private: boolean;
  shared: boolean;
}

const TemplateContent: FC<{ onClose: () => void }> = ({ onClose }) => {

  const { setRows, setSheetCreateLoading, setActiveGeneratedSheet, generateBanners, setTemplate } = useMainContext();

  const { actions, activePage } = useEditor((state) => ({
    activePage: state.activePage,
  }));

  const addPage = async (id: String) => {
    // fetch template by id
    try {
      setSheetCreateLoading(true)
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/detail/${id}`);
      const data = response.data?.data?.layers;
      setTemplate(response.data?.data);
      console.log("data", data);
      actions.setData([{
        layers: data,
      }]);
      setActiveGeneratedSheet("");
      setSheetCreateLoading(false)
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || "Something went wrong! Please try again.");
      setSheetCreateLoading(false)
    }
    if (isMobile) {
      onClose();
    }
  };


  const data = [
    {
      label: "My",
      value: "my",
      desc: <MyTemplates addPage={addPage} generateBanners={generateBanners} actions={actions} />,
      icon: RiAccountCircleLine
    },
    {
      label: "All",
      value: "all",
      desc: <AllTemplates addPage={addPage} generateBanners={generateBanners} actions={actions} />,
      icon: RiGlobalLine
    },

    {
      label: "Featured",
      value: "global",
      desc: <GlobalTemplates addPage={addPage} generateBanners={generateBanners} actions={actions} />,
      icon: BsStars
    }
  ];

  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Templates
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClose}
        >
          <XIcon />
        </div>
      </div>

      <div
        css={{
          flexDirection: 'column',
          overflowY: 'auto',
          display: 'flex',
          flexGrow: 1,
        }}
      >
        <Tabs value="my" css={{
          margin: 16,
          zIndex: 0,
        }}>
          <TabsHeader placeholder={{}}>
            {data.map(({ label, value, icon }) => (
              <Tab placeholder={{}} key={value} value={value}>
                <div className="flex items-center gap-2">
                  {React.createElement(icon, { className: "w-5 h-5" })}
                  {label}
                </div>
              </Tab>
            ))}
          </TabsHeader>

          <TabsBody placeholder={{}} style={{
            height: '100%',
            overflowY: 'hidden'
          }}>
            {data.map(({ value, desc }) => (
              <TabPanel style={{
                padding: 0,
                paddingBottom: 56,
                paddingRight: 8,
                margin: 0,
                height: '100%',
                overflowY: 'auto',
              }} key={value} value={value}>
                {desc}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
};

export default TemplateContent;







const MyTemplates = ({
  addPage,
  generateBanners,
  actions
}) => {


  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


  const buildParams = (data: Record<string, string | string[]>) => {
    const params = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((v) => params.append(key, v));
      } else {
        params.append(key, value);
      }
    });

    return params;
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template?${buildParams({
        limit: String(limit),
        skip: String(skip),
        q: query
      })}`);
      console.log("templates", response.data);
      setTemplates(response.data?.data);
      setFilteredTemplates(response.data?.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }
  useAsync(async () => {
    // const response = await axios.get<Template[]>('/templates');
    fetchTemplates();
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (query.length > 0) {
    //   const filtered = templates.filter((item) => item.templateName.toLowerCase().includes(query.toLowerCase()));
    //   setFilteredTemplates(filtered);
    // } else {
    //   setFilteredTemplates(templates);
    // }
    fetchTemplates();
  }

  const deleteTemplate = async (id: string, name: string) => {
    toast(
      (t) => (
        <div>
          <div>Are you sure you want to delete this template?</div>
          <code className='mt-2'>"{name}"</code>
          <div className='w-full flex justify-end gap-2 mt-2'>
            <button
              onClick={async () => {
                try {
                  await axios.delete(`${process.env.NEXT_PUBLIC_BASE_URL}/template/${id}`);
                  toast.success('Template deleted successfully', {
                    id: 'delete-template-success'
                  });
                  fetchTemplates();
                } catch (err) {
                  console.log(err);
                  toast.error(err.response?.data?.message || "Something went wrong! Please try again.", {
                    id: 'delete-template-error'
                  });
                }
                toast.dismiss(t.id);
              }}
              className='bg-red-500 text-white px-4 py-2 rounded-md'
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className='bg-blue-500 text-white px-4 py-2 rounded-md'
            >
              No
            </button>
          </div>
        </div>
      ),
      {
        duration: 10000,
        id: 'delete-template'
      }
    );
  }

  const changeVisibility = async (id: string, name: string, isPublic: boolean) => {
    toast(
      (t) => (
        <div>
          <div>Are you sure you want to make this template {isPublic ? "private" : "public"}?</div>
          <code className='mt-2'>"{name}"</code>
          <div className='w-full flex justify-end gap-2 mt-2'>
            <button
              onClick={async () => {
                try {
                  await axios.patch(`${process.env.NEXT_PUBLIC_BASE_URL}/template/${isPublic ? "mark-private" : "mark-public"}`, {
                    id
                  });
                  toast.success('Template visibility changed successfully', {
                    id: 'change-visibility-success'
                  });
                  fetchTemplates();
                } catch (err) {
                  console.log(err);
                  toast.error(err.response?.data?.message || "Something went wrong! Please try again.", {
                    id: 'change-visibility-error'
                  });
                }
                toast.dismiss(t.id);
              }}
              className='bg-red-500 text-white px-4 py-2 rounded-md'
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className='bg-blue-500 text-white px-4 py-2 rounded-md'
            >
              No
            </button>
          </div>
        </div>
      ),
      {
        duration: 10000,
        id: 'change-visibility'
      }
    );
  }

  const handleShare = async (id: string, shared: boolean, action: 'share' | 'unshare') => {
    toast(
      (t) => (
        <div>
          {action === 'share' && <div>
            {shared ? <div>
              <p>This template is already shared.</p>
              <div>
                <code className='mt-2'>Share Link: {`${window.location.origin}?sharedtemplateid=${id}`}</code>
                <button onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}?sharedtemplateid=${id}`);
                  toast.success((
                    <div>
                      <p>Copied to clipboard</p>
                    </div>
                  ), {
                    id: 'share-template-link-copy'
                  });
                }
                } className='bg-blue-500 text-white px-3 py-1.5 ml-3 rounded-md'>
                  <BsCopy size={16} className='w-4 h-4' />
                </button>
              </div>
            </div>
              : <div>
                <p>Are you sure you want to share this template?</p>
                <div className='w-full flex justify-end gap-2 mt-2'>
                  <button
                    onClick={async () => {
                      try {
                        await axios.patch(`${process.env.NEXT_PUBLIC_BASE_URL}/template/share`, {
                          id
                        });
                        toast((t) => (
                          <div>
                            <p>Sharable link generated successfully</p>
                            <div>
                              <code className='mt-2'>Share Link: {`${window.location.origin}?sharedtemplateid=${id}`}</code>
                              <button onClick={() => {
                                navigator.clipboard.writeText(`${window.location.origin}?sharedtemplateid=${id}`);
                                toast.success((
                                  <div>
                                    <p>Copied to clipboard</p>
                                  </div>
                                ), {
                                  id: 'share-template-link-copy'
                                });
                              }
                              } className='bg-blue-500 text-white px-3 py-1.5 ml-3 rounded-md'>
                                <BsCopy size={16} className='w-4 h-4' />
                              </button>
                            </div>
                          </div>
                        ), {
                          id: 'share-template-success',
                          duration: 10000
                        });
                        fetchTemplates();
                      } catch (err) {
                        console.log(err);
                        toast.error(err.response?.data?.message || "Something went wrong! Please try again.", {
                          id: 'change-visibility-error'
                        });
                      }
                      toast.dismiss(t.id);
                    }}
                    className='bg-red-500 text-white px-4 py-2 rounded-md'
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className='bg-blue-500 text-white px-4 py-2 rounded-md'
                  >
                    No
                  </button>
                </div>
              </div>}
          </div>
          }
          {action === 'unshare' && <div>
            <p>Are you sure you want to remove share from this template?</p>
            <div className='w-full flex justify-end gap-2 mt-2'>
              <button
                onClick={async () => {
                  try {
                    await axios.patch(`${process.env.NEXT_PUBLIC_BASE_URL}/template/unshare`, {
                      id
                    });
                    toast.success('Template share removed successfully', {
                      id: 'change-visibility-success'
                    });
                    fetchTemplates();
                  } catch (err) {
                    console.log(err);
                    toast.error(err.response?.data?.message || "Something went wrong! Please try again.", {
                      id: 'change-visibility-error'
                    });
                  }
                  toast.dismiss(t.id);
                }}
                className='bg-red-500 text-white px-4 py-2 rounded-md'
              >
                Yes
              </button>
              <button
                onClick={() => toast.dismiss(t.id)}
                className='bg-blue-500 text-white px-4 py-2 rounded-md'
              >
                No
              </button>
            </div>
          </div>}
        </div>
      ),
      {
        duration: 5000,
        id: 'change-visibility'
      }
    );
  }

  return (
    <div
      css={{
        flexDirection: 'column',
        // overflowY: 'auto',
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <div
        css={{
          // borderRadius: 4,
          // boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
          paddingBlock: 16,
          paddingInline: 2,
          position: 'sticky',
          top: 0,
          backdropFilter: 'blur(20px)',
          backgroundColor: '#fff'
        }}
      >
        <div
          css={{
            borderRadius: 4,
            boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
            height: 40,
            padding: '0 12px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff'
          }}
        >
          <div css={{ fontSize: 24, marginRight: 8, flexShrink: 0 }}>
            <MagnifyingGlassIcon />
          </div>
          <form onSubmit={handleSearch}>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              css={{ width: '100%', height: '100%' }}
              type={'text'}
            />
          </form>
        </div>
      </div>
      <div
        css={{ flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      >
        <div
          css={{
            flexGrow: 1,
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 8,
            // padding: '16px',
            height: '100%'
          }}
        >
          {isLoading && <div>Loading...</div>}
          {filteredTemplates?.map((item, index) => (
            <div
              key={index}
              className='flex gap-4 shadow-sm pb-2 mb-2'
            >
              <div className='w-full hover:scale-95 transition-all duration-300' onClick={() => {
                addPage(item._id);
              }}>
                <img loading="lazy" src={item.templateImage} />
              </div>
              <div className='w-full flex flex-col gap-2 justify-between'>
                <div className='w-full flex flex-col'>

                  <div className='w-full flex gap-1 justify-between'>
                    <h3 className='text-gray-800 text-lg font-semibold hover:text-[#3662E3] transition-all duration-150 line-clamp-1' onClick={() => addPage(item._id)}>{item.templateName}</h3>

                    <Menu placement='right-start'>
                      <MenuHandler>
                        <button className='text-gray-600 hover:text-gray-900 transition-all duration-300'>
                          <BsThreeDotsVertical className='w-4 h-4' />
                        </button>
                      </MenuHandler>
                      <MenuList
                        css={{
                          padding: '6px',
                          borderRadius: 8,
                          cursor: 'pointer',
                        }}
                        placeholder={{}}
                      >
                        <MenuItem placeholder={{}} onClick={() => deleteTemplate(item._id, item.templateName)} className='text-red-600'>Delete</MenuItem>
                        <MenuItem placeholder={{}} onClick={() => {
                          if (item.private) {
                            changeVisibility(item._id, item.templateName, false);
                          } else {
                            changeVisibility(item._id, item.templateName, true);
                          }
                        }}>Make {item.private ? "Public" : "Private"}</MenuItem>
                        <MenuItem onClick={() => handleShare(item._id, item.shared, 'share')}>Share</MenuItem>
                        {item.shared && <MenuItem onClick={() => handleShare(item._id, item.shared, 'unshare')}>Remove Share</MenuItem>}
                      </MenuList>
                    </Menu>
                  </div>
                </div>
                <div className='flex gap-2'>
                  {/* <button onClick={() => addPage(item.meta)} className='text-blue-600 px-2.5 py-1 rounded-md border border-blue-600'>
                <FaEye className='w-5 h-5' />
              </button> */}
                  <button
                    title='Open Sheet'
                    className='text-blue-600 hover:bg-green-600/20 transition-all duration-300 px-2.5 py-1 rounded-md border border-green-600 flex items-center justify-center'
                    onClick={() => {
                      window.open(`https://docs.google.com/spreadsheets/d/${item.sheetId}/edit#gid=0`)
                    }}
                  >
                    <div className='w-5 h-5'>
                      <img src={SheetIcon} className='w-full h-full object-contain' />
                    </div>
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      if (item.sheetId) {
                        generateBanners(item.sheetId, "latest", actions);
                      }
                    }}
                    className="w-full inline-flex gap-2 items-center justify-center px-4 py-2 leading-6 text-white whitespace-no-wrap bg-[#3662E3] border border-[#334b93] rounded-md shadow-sm hover:bg-[#243463] focus:outline-none font-bold text-lg whitespace-nowrap">
                    {/* <SlMagicWand className='w-5 h-5' /> */}
                    Generate
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

  )
}

const AllTemplates = ({
  addPage,
  generateBanners,
  actions
}) => {


  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(true);



  const buildParams = (data: Record<string, string | string[]>) => {
    const params = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((v) => params.append(key, v));
      } else {
        params.append(key, value);
      }
    });

    return params;
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/all?${buildParams({
        limit: String(limit),
        skip: String(skip),
        q: query
      })}`);
      console.log("templates", response.data);
      setTemplates(response.data?.data);
      setFilteredTemplates(response.data?.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }
  useAsync(async () => {
    // const response = await axios.get<Template[]>('/templates');
    fetchTemplates();
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (query.length > 0) {
    //   const filtered = templates.filter((item) => item.templateName.toLowerCase().includes(query.toLowerCase()));
    //   setFilteredTemplates(filtered);
    // } else {
    //   setFilteredTemplates(templates);
    // }
    fetchTemplates();
  }





  return (
    <div
      css={{
        flexDirection: 'column',
        // overflowY: 'auto',
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <div
        css={{
          // borderRadius: 4,
          // boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
          paddingBlock: 16,
          paddingInline: 2,
          position: 'sticky',
          top: 0,
          backdropFilter: 'blur(20px)',
          backgroundColor: '#fff'
        }}
      >
        <div
          css={{
            borderRadius: 4,
            boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
            height: 40,
            padding: '0 12px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff'
          }}
        >
          <div css={{ fontSize: 24, marginRight: 8, flexShrink: 0 }}>
            <MagnifyingGlassIcon />
          </div>
          <form onSubmit={handleSearch}>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              css={{ width: '100%', height: '100%' }}
              type={'text'}
            />
          </form>
        </div>
      </div>
      <div
        css={{ flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      >
        <div
          css={{
            flexGrow: 1,
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 8,
            // padding: '16px',
            height: '100%'
          }}
        >
          {isLoading && <div>Loading...</div>}
          {filteredTemplates?.map((item, index) => (
            <div
              key={index}
              className='flex gap-4 shadow-sm pb-2 mb-2'
            >
              <div className='w-full hover:scale-95 transition-all duration-300' onClick={() => {
                addPage(item._id);
              }}>
                <img loading="lazy" src={item.templateImage} />
              </div>
              <div className='w-full flex flex-col gap-2 justify-between'>
                <div className='w-full flex flex-col'>

                  <div className='w-full'>
                    <h3 className='text-gray-800 text-lg font-semibold hover:text-[#3662E3] transition-all duration-150' onClick={() => addPage(item._id)}>{item.templateName}</h3>
                    {/* <button title='View' onClick={() => addPage(item.meta)} className='text-blue-600 hover:text-white px-2 py-0.5 rounded-md border border-blue-600 hover:bg-blue-600 transition-all duration-300'>
                  <FaEye className='w-4 h-4' />
                </button> */}
                  </div>
                </div>
                <div className='flex gap-2'>
                  {/* <button onClick={() => addPage(item.meta)} className='text-blue-600 px-2.5 py-1 rounded-md border border-blue-600'>
                <FaEye className='w-5 h-5' />
              </button> */}
                  <button
                    title='Open Sheet'
                    className='text-blue-600 hover:bg-green-600/20 transition-all duration-300 px-2.5 py-1 rounded-md border border-green-600 flex items-center justify-center'
                    onClick={() => {
                      window.open(`https://docs.google.com/spreadsheets/d/${item.sheetId}/edit#gid=0`)
                    }}
                  >
                    <div className='w-5 h-5'>
                      <img src={SheetIcon} className='w-full h-full object-contain' />
                    </div>
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      if (item.sheetId) {
                        generateBanners(item.sheetId, "latest", actions);
                      }
                    }}
                    className="w-full inline-flex gap-2 items-center justify-center px-4 py-2 leading-6 text-white whitespace-no-wrap bg-[#3662E3] border border-[#334b93] rounded-md shadow-sm hover:bg-[#243463] focus:outline-none font-bold text-lg whitespace-nowrap">
                    {/* <SlMagicWand className='w-5 h-5' /> */}
                    Generate
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

  )
}

const GlobalTemplates = ({
  addPage,
  generateBanners,
  actions
}) => {


  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(true);



  const buildParams = (data: Record<string, string | string[]>) => {
    const params = new URLSearchParams();

    Object.entries(data).forEach(([key, value]) => {
      if (isArray(value)) {
        value.forEach((v) => params.append(key, v));
      } else {
        params.append(key, value);
      }
    });

    return params;
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/all?${buildParams({
        limit: String(limit),
        skip: String(skip),
        q: query
      })}`);
      console.log("templates", response.data);
      setTemplates(response.data?.data);
      setFilteredTemplates(response.data?.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }
  // useAsync(async () => {
  //   // const response = await axios.get<Template[]>('/templates');
  //   fetchTemplates();
  // }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // if (query.length > 0) {
    //   const filtered = templates.filter((item) => item.templateName.toLowerCase().includes(query.toLowerCase()));
    //   setFilteredTemplates(filtered);
    // } else {
    //   setFilteredTemplates(templates);
    // }
    fetchTemplates();
  }





  return (
    <div
      css={{
        flexDirection: 'column',
        // overflowY: 'auto',
        display: 'flex',
        flexGrow: 1,
      }}
    >
      {/* <div
        css={{
          // borderRadius: 4,
          // boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
          paddingBlock: 16,
          paddingInline: 2,
          position: 'sticky',
          top: 0,
          backdropFilter: 'blur(20px)',
          backgroundColor: '#fff'
        }}
      >
        <div
          css={{
            borderRadius: 4,
            boxShadow: '0 0 0 1px rgba(43,59,74,.3)',
            height: 40,
            padding: '0 12px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#fff'
          }}
        >
          <div css={{ fontSize: 24, marginRight: 8, flexShrink: 0 }}>
            <MagnifyingGlassIcon />
          </div>
          <form onSubmit={handleSearch}>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              css={{ width: '100%', height: '100%' }}
              type={'text'}
            />
          </form>
        </div>
      </div>
      <div
        css={{ flexDirection: 'column', overflowY: 'auto', display: 'flex' }}
      >
        <div
          css={{
            flexGrow: 1,
            overflowY: 'auto',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridGap: 8,
            // padding: '16px',
            height: '100%'
          }}
        >
          {isLoading && <div>Loading...</div>}
          {filteredTemplates?.map((item, index) => (
            <div
              key={index}
              className='flex gap-4 shadow-sm pb-2 mb-2'
            >
              <div className='w-full hover:scale-95 transition-all duration-300' onClick={() => {
                addPage(item._id);
              }}>
                <img loading="lazy" src={item.templateImage} />
              </div>
              <div className='w-full flex flex-col gap-2 justify-between'>
                <div className='w-full flex flex-col'>

                  <div className='w-full'>
                    <h3 className='text-gray-800 text-lg font-semibold hover:text-[#3662E3] transition-all duration-150' onClick={() => addPage(item._id)}>{item.templateName}</h3>
                  </div>
                </div>
                <div className='flex gap-2'>
                  <button
                    title='Open Sheet'
                    className='text-blue-600 hover:bg-green-600/20 transition-all duration-300 px-2.5 py-1 rounded-md border border-green-600 flex items-center justify-center'
                    onClick={() => {
                      window.open(`https://docs.google.com/spreadsheets/d/${item.sheetId}/edit#gid=0`)
                    }}
                  >
                    <div className='w-5 h-5'>
                      <img src={SheetIcon} className='w-full h-full object-contain' />
                    </div>
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      if (item.sheetId) {
                        generateBanners(item.sheetId, "latest", actions);
                      }
                    }}
                    className="w-full inline-flex gap-2 items-center justify-center px-4 py-2 leading-6 text-white whitespace-no-wrap bg-[#3662E3] border border-[#334b93] rounded-md shadow-sm hover:bg-[#243463] focus:outline-none font-bold text-lg whitespace-nowrap">
                    Generate
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}

      {/* Comming Soon.... */}
      <div className='flex items-center justify-center h-52'>
        <h1>Comming Soon...</h1>
      </div>
    </div>

  )
}