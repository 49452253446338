// @ts-nocheck

import PlayCircleIcon from '@duyank/icons/regular/PlayCircle';
import { useEditor } from 'lidojs/design-editor';
import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import { downloadObjectAsJson } from '../utils/download';
import { toPng, toJpeg, toBlob, toSvg, toPixelData } from 'html-to-image';
import SheetIcon from 'src/assets/sheet-logo.svg';
import axios from 'src/utils/axiosInterceptorInstance';
import { MainContextType, useMainContext } from 'src/context/MainContext';
import { extractTextFromHTML } from 'src/utils/htmlParse';
import JSZip from 'jszip';
import toast from 'react-hot-toast';
import { RxCross2 } from 'react-icons/rx';
import { HiOutlineDownload } from 'react-icons/hi';
import { GrFormCheckmark } from 'react-icons/gr';
import { MdLogout } from 'react-icons/md';
import jsPDF from 'jspdf';
import { getPageSize } from 'lidojs/design-core';
import { Tab, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react';
import { LuHistory } from 'react-icons/lu';
import { BsFiletypePsd, BsPlus, BsStars } from 'react-icons/bs';
import { emptyPage } from 'src/pages/new';
import toPanvaJSON from "src/utils/psdJsonToPanvaJson"
import Psd from "../utils/psdToJson";
import { v4 as uuid } from "uuid";
import PreviewBatchModal from 'src/components/PreviewBatchModal';
import { PiSealWarningBold } from 'react-icons/pi';

interface HeaderLayoutProps {
  openPreview: () => void;
}

const HeaderLayout: ForwardRefRenderFunction<
  HTMLDivElement,
  HeaderLayoutProps
> = ({ openPreview }, ref) => {
  const uploadRef = useRef<HTMLInputElement>(null);
  const uploadPSDRef = useRef<HTMLInputElement>(null);
  const { actions, query, state } = useEditor();

  const { extras, setExtras, setSheetCreateLoading, sheetCreateLoading, rows, setRows, activeGeneratedSheet, generateBanners, end, start, generateTab, setGenerateTab, setTemplateKey, user, updateBatchResponse, setUpdateBatchResponse, template, setTemplate }: MainContextType = useMainContext();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleExport = () => {
    downloadObjectAsJson('file', query.serialize());
  };
  const handleImport = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      console.log("file", file);
      reader.onload = function () {
        const fileContent = JSON.parse(reader.result as string);
        actions.setData(fileContent);
      };
      reader.readAsText(file);
      e.target.value = '';
    }
  };

  const handlePSDImport = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setSheetCreateLoading(true);
    const instance = new Psd({
      uploadUrl: e.target.files[0].name,
      uploadCallback: (data) => {
        console.log("data", data);
        return "hello"
      }
    });
    const result = await instance.init(e.target.files[0]);
    console.log("result", result);
    const panvaJson = toPanvaJSON(result.json);
    console.log("panvaJson", panvaJson);
    actions.setData(panvaJson);
    setSheetCreateLoading(false);
  };

  useEffect(() => {
    console.log("state", state);
    console.log("query", query.serialize());


    if (!state.pages[0]?.layers) {
      return;
    }
    // set all the layers identifier
    const layerKeys = Object.keys(state.pages[0]?.layers);
    const newExtras = {};
    layerKeys.forEach((key, index) => {
      if (state.pages[0].layers[key].data?.type === 'Text' || state.pages[0].layers[key].data?.type === 'Image') {
        if (!extras[key]) {
          newExtras[key] = {
            identifier: state.pages[0].layers[key].data?.type === 'Text' ? `text-${Object.keys(state.pages[0].layers).filter((layer) => state.pages[0].layers[layer].data?.type === 'Text').length}` : `image-${Object.keys(state.pages[0].layers).filter((layer) => state.pages[0].layers[layer].data?.type === 'Image').length}`,
            value: state.pages[0].layers[key].data?.type === 'Text' ? extractTextFromHTML(state.pages[0].layers[key]?.data?.props?.text) : state.pages[0].layers[key]?.data?.props?.image?.url,
            id: key
          }
        } else {
          newExtras[key] = extras[key]
        }
      }
    })
    setExtras(newExtras);
  }, [state.pages[0]?.layers])

  const data = [
    {
      label: "Previous",
      value: "prev",
      desc: `It really matters and then like it really doesn't matter.
      What matters is the people who are sparked by it. And the people
      who are like offended by it, it doesn't matter.`,
      icon: LuHistory
    },
    {
      label: "Latest",
      value: "latest",
      desc: `Because it's about motivating the doers. Because I'm here
      to follow my dreams and inspire other people to follow their dreams, too.`,
      icon: BsStars
    }
  ];


  const convertToPDF = (images: string[]) => {

    const img = new Image();
    img.src = images[0];

    const pageWidth = getPageSize(query.serialize()).width;
    const pageHeight = getPageSize(query.serialize()).height;

    img.onload = () => {


      const width = img.width;
      const height = img.height;

      console.log("img", img.width, img.height);
      console.log("editor", pageWidth, pageHeight);

      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'pt',
        format: [height, width]
      });

      console.log("images in fun", images);

      const promises = images.map((image, index) => {
        return new Promise(async (resolve) => {
          doc.addImage(image, 'JPEG', 0, 0, width, height); // You can adjust the position and size here
          if (index < images.length - 1) {
            doc.addPage()
          }
          resolve(
            console.log('Image converted to PDF successfully')
          );
        });
      });

      Promise.all(promises).then(() => {
        doc.save('images.pdf');
      });

    }
  };

  const addCorsTagToAllImages = (element: HTMLElement) => {
    if (element) {
      const images = element.querySelectorAll('img');
      images.forEach((img) => {
        img.setAttribute('crossorigin', 'anonymous');
        // add random number to the image url to avoid caching
        img.setAttribute('src', img.getAttribute('src') + '?' + 'randomstr=' + Math.random());
      });
    }
    console.log("element with cors tag", element);
    return element;
  }

  const downloadImage = async (typeOfFile: String) => {


    console.log("downloadImage");

    actions.hoverLayer(0, '0');
    actions.resetSelectLayer();
    actions.setScale(1);

    setTimeout(async () => {
      console.log(document.querySelectorAll('#editor-content > div > div > div > div > div > div'));

      const elements = document.querySelectorAll('#editor-content > div > div > div > div > div > div') as NodeListOf<HTMLElement>;
      console.log("element", elements);
      if (elements) {
        if (typeOfFile === 'pdf') {
          // dont take last element
          const eles = Array.from(elements)
          console.log("elements b4 pop", eles);
          eles.pop();
          console.log("elements after pop", eles);

          const images = eles.map(async (element, index) => {
            return await toPng(addCorsTagToAllImages(element?.lastChild) as HTMLElement, {
              width: getPageSize(query.serialize()).width,
              height: getPageSize(query.serialize()).height
            }).then((dataUrl) => {
              return dataUrl;
            }).catch((err) => {
              console.log("=====================================");
              console.log("toPng pdf err", err);
              console.log("=====================================");
              return null;
            });
          });

          console.log("images", images);

          const data = await Promise.all(images);
          console.log("data", data);
          convertToPDF(data);
          return;
        }
        const zip = new JSZip();
        // loop through all the elements and convert them to images
        for (let i = 0; i < elements.length - 1; i++) {
          const element = elements[i]?.lastChild as HTMLElement;

          console.log("element " + i + " ====>", element);
          if (typeOfFile === 'png') {
            try {
              const dataUrl = await toPng(addCorsTagToAllImages(element));
              zip.file(rows.at(i)?.at(0) ? `${rows.at(i)?.at(0)}.png` : `image_${i}.png`, dataUrl.split(',')[1], { base64: true });
            } catch (err) {
              console.log("=====================================");
              console.log("toPng err", err);
              console.log("=====================================");
            }
          }
          else if (typeOfFile === 'jpg') {
            try {
              const dataUrl = await toJpeg(addCorsTagToAllImages(element));
              zip.file(rows.at(i)?.at(0) ? `${rows.at(i)?.at(0)}.jpg` : `image_${i}.jpg`, dataUrl.split(',')[1], { base64: true });
            } catch (err) {
              console.log("=====================================");
              console.log("toJpeg err", err);
              console.log("=====================================");
            }
          }
          // else if (typeOfFile === 'svg') {
          //   const dataUrl = await toSvg(element);
          //   zip.file(rows.at(i)?.at(0) ? `${rows.at(i)?.at(0)}.svg` : `image_${i}.svg`, dataUrl.split(',')[1], { base64: true });
          // } 
          else {
            try {
              const blob = await toBlob(addCorsTagToAllImages(element));
              // const blobUrl = URL.createObjectURL(blob);
              // console.log("blobUrl", blobUrl);
              zip.file(rows.at(i)?.at(0) ? `${rows.at(i)?.at(0)}.png` : `image_${i}.png`, blob);
            } catch (err) {
              console.log("=====================================");
              console.log("blob err", err);
              console.log("=====================================");
            }
          }
          console.log("zip", zip);
        }



        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        console.log("zipBlob", zipBlob);

        // Create a download link for the zip file
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(zipBlob);
        downloadLink.download = 'images.zip';
        console.log("downloadLink", downloadLink);
        try {
          downloadLink.click();
        } catch (err) {
          console.log("=====================================");
          console.log("download err", err);
          console.log("=====================================");
        }
      }
    }, 100);


  }

  const urlToFile = async (url: string, filename: string) => {
    return fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename); })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }


  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, text) => {
    try {

      setSheetCreateLoading(true);


      // create image from html and save it to cdn
      const templates = document.querySelectorAll('#editor-content > div > div > div > div > div > div')
      if (!templates || templates.length === 0) {
        setSheetCreateLoading(false);
        return;
      }

      const template = templates[0]?.lastChild as HTMLElement;
      const blob = await toBlob(addCorsTagToAllImages(template));
      // blob to File
      const file = new File([blob], "template.png");
      // File to FormData
      const formData = new FormData();
      formData.append('file', file);
      const imgUrl = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/template/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        console.log(res);
        return res.data.data?.data?.baseUrl + res.data.data?.data?.key;
      })
        .catch(err => {
          console.log(err);
          return '';
        });
      if (!imgUrl) {
        return;
      }

      console.log("imgUrl", imgUrl);

      const tempData = query.serialize()[0];
      // find all the text layers and add one more key to it
      Object.entries(tempData.layers).forEach(async ([key, value], index) => {
        if (value.type.resolvedName === 'TextLayer') {
          value["textData"] = extractTextFromHTML(value.props.text);
        }
      })

      const data = {
        ...tempData,
        extras: extras,
        templateName: text,
        templateImage: imgUrl
      }

      const res = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/template`, data, {
        headers: {
          'content-type': 'application/json'
        }
      })

      console.log("res", res);

      toast((t) => (
        <span className='flex gap-2 items-center'>
          <p className='whitespace-nowrap text-lg'> Sheet Created Successfully!</p>
          <a href={`https://docs.google.com/spreadsheets/d/${res.data?.data?.data?.spreadsheetId}/edit#gid=0`} target="_blank" rel="noreferrer" className='text-lg bg-blue-600 text-white px-4 py-1.5 rounded-md whitespace-nowrap'>View</a>
          <button onClick={() => toast.dismiss(t.id)}
            className='text-lg text-gray-500 w-6 h-6 flex justify-center items-center rounded-full hover:bg-gray-200'
          >
            <RxCross2 />
          </button>
        </span>
      ), {
        id: 'createSheetSuccess',
        duration: 30000,
        position: 'top-center',
      });

      setTemplateKey(uuid());

      console.log("tempData", tempData);

    } catch (error) {
      console.log("error", error);
      alert("Error")
    } finally {
      setSheetCreateLoading(false);
    }
  }

  const createNewTemplate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // toast for confirmaion
    toast((t) => (
      <span className='flex flex-col gap-2'>
        <p className='whitespace-nowrap text-lg'> Are you sure you want to create a new template?</p>
        <div className='w-full flex justify-end gap-2'>
          <button onClick={() => {
            console.log("emptyPage", emptyPage);
            actions.setData(emptyPage);
            setTemplate(null)
            toast.dismiss(t.id);
          }}
            className='text-lg bg-green-600 text-white px-4 py-1.5 rounded-md whitespace-nowrap'
          >
            Yes
          </button>
          <button onClick={() => toast.dismiss(t.id)}
            className='text-lg bg-red-600 text-white px-4 py-1.5 rounded-md whitespace-nowrap'
          >
            No
          </button>
        </div>
      </span>
    ), {
      id: 'createNewTemplate',
      duration: 30000,
      position: 'top-center',
      style: {
        // width: '100rem'
        maxWidth: '100%'
      }
    });
  }

  const updateSheet = async (sheetId) => {
    console.log("updateSheet");

    actions.hoverLayer(0, '0');
    actions.resetSelectLayer();
    actions.setScale(1);

    setTimeout(async () => {
      try {
        setSheetCreateLoading(true);
        console.log(document.querySelectorAll('#editor-content > div > div > div > div > div > div'));

        const elements = document.querySelectorAll('#editor-content > div > div > div > div > div > div') as NodeListOf<HTMLElement>;
        console.log("element", elements);

        const imagesPromise = [];
        if (elements) {
          // loop through all the elements and convert them to images
          for (let i = 0; i < elements.length - 1; i++) {
            const element = elements[i]?.lastChild as HTMLElement;
            try {
              const dataUrl = await toPng(addCorsTagToAllImages(element));
              console.log("dataUrl", dataUrl);
              if (dataUrl) {
                imagesPromise.push(uploadFilesToCDN(dataUrl));
              } else {
                imagesPromise.push("false");
              }
            } catch (err) {
              console.log("error", err);
              imagesPromise.push("false");
              throw new Error("Error converting to image. There might be some layers that are not converted to image. Please check and try again!");
            }
          }
        }

        if (end === "" || start === "") {
          toast.error("Regenerate the sheet first!");
          return;
        }

        const images = await Promise.all(imagesPromise);

        if (images.length === 0) {
          toast.error("No images found!");
          return;
        }
        console.log("images", images);

        const data = {
          sheetId,
          end: String(end),
          start: String(start),
          result: images
        }
        const res = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/template/save-result`, data, {
          headers: {
            'content-type': 'application/json'
          }
        })

        console.log("res", res);

        toast((t) => (
          <span className='flex gap-2 items-center'>
            <p className='whitespace-nowrap text-lg'> Sheet Updated Successfully!</p>
            <a href={`https://docs.google.com/spreadsheets/d/${res.data?.data?.data?.spreadsheetId}/edit#gid=0`} target="_blank" rel="noreferrer" className='text-lg bg-blue-600 text-white px-4 py-1.5 rounded-md whitespace-nowrap'>View</a>
            <button onClick={() => toast.dismiss(t.id)}
              className='text-lg text-gray-500 w-6 h-6 flex justify-center items-center rounded-full hover:bg-gray-200'
            >
              <RxCross2 />
            </button>
          </span>
        ), {
          id: 'updateSheetSuccess',
          duration: 5000,
          position: 'top-center',
        });

        setSheetCreateLoading(false);
      } catch (err) {
        console.log("err", err);
        toast.error("Error updating sheet!");
        setSheetCreateLoading(false);
      }
    }, 0);
  }

  const uploadFilesToCDN = async (dataUrl) => {
    const file = await urlToFile(dataUrl, "template.png");
    // File to FormData
    const formData = new FormData();
    formData.append('file', file);
    const imgUrl = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/template/upload-file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(res => {
      console.log(res);
      return res.data.data?.data?.baseUrl + res.data.data?.data?.key;
    })
      .catch(err => {
        console.log(err);
        return '';
      });
    return imgUrl;
  }

  const handleBannerUpdateOnPwLive = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, sheetId: String) => {
    toast(
      (t) => (
        <div>
          <div>Are you sure you want to upload all the banners of this template to pw.live?</div>
          <code className='mt-2 break-all'>Sheet ID: "{sheetId}"</code>
          <div className='w-full flex justify-end gap-2 mt-2'>
            <button
              onClick={async () => {
                // redirect to the sheet
                window.open(`https://docs.google.com/spreadsheets/d/${sheetId}/edit#gid=0`, '_blank');
              }}
              className='bg-green-500 text-white px-4 py-2 rounded-md'
            >
              View Sheet
            </button>
            <button
              onClick={async () => {
                try {
                  // fetch the api to upload the banners to pw.live
                  const res = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/update-batch-preview/${sheetId}`);
                  console.log("res", res);
                  if (res.data.data.data?.length) {
                    setUpdateBatchResponse(res.data.data);
                  }

                  toast(res.data.data?.message || "Banners uploaded successfully!", {
                    id: 'upload-banners-success',
                    icon: <PiSealWarningBold className='w-9 h-9 text-yellow-500' />
                  });
                } catch (err) {
                  console.log(err);
                  toast.error(err.response?.data?.message || "Something went wrong! Please try again.", {
                    id: 'upload-banners-error'
                  });
                }
                toast.dismiss(t.id);
              }}
              className='bg-blue-500 text-white px-4 py-2 rounded-md'
            >
              Yes
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className='bg-slate-300 text-gray-800 border border-gray-800 px-4 py-2 rounded-md'
            >
              No
            </button>
          </div>
        </div>
      ),
      {
        duration: 10000,
        id: 'delete-template'
      }
    );
  }

  const handleSaveAsDraft = async () => {
    console.log("template save as draft :", template);
    try {

      setSheetCreateLoading(true);


      // create image from html and save it to cdn
      const templates = document.querySelectorAll('#editor-content > div > div > div > div > div > div')
      if (!templates || templates.length === 0) {
        setSheetCreateLoading(false);
        return;
      }

      const templateData = templates[0]?.lastChild as HTMLElement;
      const blob = await toBlob(addCorsTagToAllImages(templateData));
      // blob to File
      const file = new File([blob], "template.png");
      // File to FormData
      const formData = new FormData();
      formData.append('file', file);
      const imgUrl = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/template/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        console.log(res);
        return res.data.data?.data?.baseUrl + res.data.data?.data?.key;
      })
        .catch(err => {
          console.log(err);
          return '';
        });
      if (!imgUrl) {
        return;
      }

      console.log("imgUrl", imgUrl);

      const tempData = query.serialize()[0];
      // find all the text layers and add one more key to it
      Object.entries(tempData.layers).forEach(async ([key, value], index) => {
        if (value.type.resolvedName === 'TextLayer') {
          value["textData"] = extractTextFromHTML(value.props.text);
        }
      })

      const data = {
        ...tempData,
        extras: extras,
        templateImage: imgUrl
      }

      const res = await axios.patch(`${process.env.NEXT_PUBLIC_BASE_URL}/template/save-as-draft/${template._id}`, data, {
        headers: {
          'content-type': 'application/json'
        }
      })

      console.log("res", res);

      toast((t) => (
        <span className='flex gap-2 items-center'>
          <p className='whitespace-nowrap text-lg'>Template Saved Successfully!</p>
          <a href={`https://docs.google.com/spreadsheets/d/${template.sheetId}/edit#gid=0`} target="_blank" rel="noreferrer" className='text-lg bg-blue-600 text-white px-4 py-1.5 rounded-md whitespace-nowrap'>View</a>
          <button onClick={() => toast.dismiss(t.id)}
            className='text-lg text-gray-500 w-6 h-6 flex justify-center items-center rounded-full hover:bg-gray-200'
          >
            <RxCross2 />
          </button>
        </span>
      ), {
        id: 'createSheetSuccess',
        duration: 30000,
        position: 'top-center',
      });

      setTemplateKey(uuid());

      console.log("tempData", tempData);

    } catch (error) {
      console.log("error", error);
      alert("Error")
    } finally {
      setSheetCreateLoading(false);
    }
  }

  const handleUpdateTemplate = async () => {
    console.log("template update :", template);
    try {

      setSheetCreateLoading(true);


      // create image from html and save it to cdn
      const templates = document.querySelectorAll('#editor-content > div > div > div > div > div > div')
      if (!templates || templates.length === 0) {
        setSheetCreateLoading(false);
        return;
      }

      const templateData = templates[0]?.lastChild as HTMLElement;
      const blob = await toBlob(addCorsTagToAllImages(templateData));
      // blob to File
      const file = new File([blob], "template.png");
      // File to FormData
      const formData = new FormData();
      formData.append('file', file);
      const imgUrl = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/template/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        console.log(res);
        return res.data.data?.data?.baseUrl + res.data.data?.data?.key;
      })
        .catch(err => {
          console.log(err);
          return '';
        });
      if (!imgUrl) {
        return;
      }

      console.log("imgUrl", imgUrl);

      const tempData = query.serialize()[0];
      // find all the text layers and add one more key to it
      Object.entries(tempData.layers).forEach(async ([key, value], index) => {
        if (value.type.resolvedName === 'TextLayer') {
          value["textData"] = extractTextFromHTML(value.props.text);
        }
      })

      const data = {
        ...tempData,
        extras: extras,
        templateImage: imgUrl
      }

      const res = await axios.patch(`${process.env.NEXT_PUBLIC_BASE_URL}/template/${template._id}`, data, {
        headers: {
          'content-type': 'application/json'
        }
      })

      console.log("res", res);

      toast((t) => (
        <span className='flex gap-2 items-center'>
          <p className='whitespace-nowrap text-lg'>Template Updated Successfully!</p>
          <a href={`https://docs.google.com/spreadsheets/d/${template.sheetId}/edit#gid=0`} target="_blank" rel="noreferrer" className='text-lg bg-blue-600 text-white px-4 py-1.5 rounded-md whitespace-nowrap'>View</a>
          <button onClick={() => toast.dismiss(t.id)}
            className='text-lg text-gray-500 w-6 h-6 flex justify-center items-center rounded-full hover:bg-gray-200'
          >
            <RxCross2 />
          </button>
        </span>
      ), {
        id: 'createSheetSuccess',
        duration: 30000,
        position: 'top-center',
      });

      setTemplateKey(uuid());

      console.log("tempData", tempData);

    } catch (error) {
      console.log("error", error);
      alert("Error")
    } finally {
      setSheetCreateLoading(false);
    }
  }


  return (
    <div
      ref={ref}
      css={{
        background: '#1E1E2D',
        padding: '12px 32px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 900px)': {
          padding: 12,
        },
      }}
    >
      {
        sheetCreateLoading && (
          <div className="fixed top-0 right-0 h-screen w-screen z-[51] flex justify-center items-center bg-black opacity-70">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-100"></div>
          </div>
        )
      }
      {
        updateBatchResponse && (
          <PreviewBatchModal />
        )
      }
      <div
        className='flex text-2xl text-[#3d8eff] gap-2 lg:gap-4 flex-wrap'
      >
        {/* <div
          css={{ color: 'white', height: 42, paddingTop: 6, paddingBottom: 6 }}
        >
          <img css={{ maxHeight: '100%' }} src={'./assets/logo.png'} />
        </div> */}
        <button
          onClick={createNewTemplate}
          className='text-sm bg-[#3a3a4bb5] text-white pl-2 pr-2 py-1.5 rounded-md flex items-center gap-2 whitespace-nowrap'
        >
          <BsPlus className='w-6 h-6' />
          {/* <span className='hidden lg:block'>New</span> */}
        </button>
        <Menu>
          <MenuHandler css={{
            backgroundColor: '#3a3a4bb5',
            color: 'white',
            padding: '7px 9px',
            borderRadius: 6,
            cursor: 'pointer',
            ':hover': {
              backgroundColor: 'rgba(58,58,76,0.5)',
            },
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            whiteSpace: 'nowrap'
          }}>
            <Button placeholder={{}} title='Download all banners'>
              <HiOutlineDownload className='w-5 h-5' />
              {/* <span className="hidden lg:block">Download</span> */}
            </Button>
          </MenuHandler>
          <MenuList
            css={{
              backgroundColor: '#3a3a4bb5',
              color: 'white',
              padding: '6px',
              borderRadius: 8,
              cursor: 'pointer',
              minWidth: 120,
              ':hover': {
                backgroundColor: 'rgba(58,58,76,0.7)',
              }
            }}
            placeholder={{
              color: 'white',
              fontWeight: 700,
              fontSize: 14,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: 4
            }}
          >
            <MenuItem placeholder={{}} onClick={() => downloadImage('png')}>PNG</MenuItem>
            <MenuItem placeholder={{}} onClick={() => downloadImage('jpg')}>JPG</MenuItem>
            <MenuItem placeholder={{}} onClick={() => downloadImage('pdf')}>PDF</MenuItem>
            {/* <MenuItem placeholder={{}} onClick={() => downloadImage('svg')}>SVG</MenuItem> */}
          </MenuList>
        </Menu>
        <button
          onClick={(e) => {
            e.preventDefault()
            const text = prompt("Template name");
            if (text) {
              handleSave(e, text);
            }
          }}
          className='text-sm bg-[#3a3a4bb5] hover:bg-[#3a3a4bb5]/40 text-white pl-2 pr-2 py-1.5 rounded-md flex items-center gap-2 whitespace-nowrap'
          title='Create this new template with the current design'
        >
          <GrFormCheckmark className='w-6 h-6' />
          <span className="hidden lg:block">Create Template</span>
        </button>
        <button
          onClick={() => {
            uploadPSDRef.current?.click();
          }}
          className='text-sm bg-[#3a3a4bb5] text-white px-2.5 py-2 rounded-md flex items-center gap-2'
          title='Import PSD file'
        >
          <BsFiletypePsd className='w-5 h-5' />
          <input
            ref={uploadPSDRef}
            accept=".psd"
            css={{ display: 'none' }}
            type="file"
            onChange={handlePSDImport}
          />
          <p className='leading-6 whitespace-nowrap hidden lg:block'>PSD Upload</p>
        </button>
        {template?._id && <button
          onClick={() => handleSaveAsDraft()}
          className='text-sm bg-[#3a3a4bb5] hover:bg-[#3a3a4bb5]/40 text-white pl-2 pr-2 py-1.5 rounded-md flex items-center gap-2 whitespace-nowrap'
          title='Save as Draft'
        >
          <GrFormCheckmark className='w-6 h-6' />
          <span className="hidden lg:block">Save as Draft</span>
        </button>}
        {template?._id && <button
          onClick={() => handleUpdateTemplate()}
          className='text-sm bg-[#3a3a4bb5] hover:bg-[#3a3a4bb5]/40 text-white pl-2 pr-2 py-1.5 rounded-md flex items-center gap-2 whitespace-nowrap'
          title='Save as Draft'
        >
          <GrFormCheckmark className='w-6 h-6' />
          <span className="hidden lg:block">Update Template</span>
        </button>}

        {
          activeGeneratedSheet && (
            <button
              onClick={(e) => {
                e.preventDefault();
                if (activeGeneratedSheet) {
                  updateSheet(activeGeneratedSheet);
                } else {
                  toast("Regenerate the sheet first!", {
                    id: "regenerate-sheet"
                  })
                }
              }}
              className='text-sm bg-[#3a3a4bb5] text-white px-2.5 py-1.5 rounded-md flex items-center gap-2 whitespace-nowrap'
              title='Update Sheet with banner urls'
            >
              <div className='w-5 h-5'>
                <img src={SheetIcon} className='w-full h-full object-contain' />
              </div>
              <span className="hidden lg:block">Update Sheet</span>
            </button>
          )
        }

        {
          activeGeneratedSheet && user?.role !== "admin" && (
            <button onClick={(e) => {
              handleBannerUpdateOnPwLive(e, activeGeneratedSheet)
            }} className="brightness-150 dark:brightness-100 group hover:shadow-lg hover:shadow-yellow-700/60 transition ease-in-out hover:scale-105 p-0.5 rounded-xl bg-gradient-to-br from-yellow-800 via-yellow-600 to-yellow-800 hover:from-yellow-700 hover:via-yellow-800 hover:to-yellow-600">
              <div className="px-2.5 py-1.5 backdrop-blur-xl bg-black/80 rounded-xl text-lg font-bold w-full">
                <div className="group-hover:scale-100 flex group-hover:text-yellow-500 text-yellow-600 gap-1 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.8"
                    className="w-5 h-5 stroke-yellow-600 group-hover:stroke-yellow-500 group-hover:stroke-{1.99}"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                    />
                  </svg>
                  Update Banners to <span className='text-white font-bold group-hover:text-yellow-500'>pw.live</span>
                </div>
              </div>
            </button>
          )
        }


        {/* <button
          onClick={(e) => {
            e.preventDefault()
            const text = prompt("Sheet Id");
            if (text) {
              generateBanners(text);
            }
          }}
          // onClick={()=>temp()}
          className='text-sm bg-[#3a3a4bb5] text-white px-4 py-1.5 rounded-md flex items-center gap-2'
        >
          <SlMagicWand className='w-6 h-6' />
          Generate banners
        </button> */}
      </div>
      {
        activeGeneratedSheet && (
          <Tabs value={generateTab}>
            <TabsHeader placeholder={{}}>
              {data.map(({ label, value, icon }) => (
                <Tab placeholder={{}} key={value} value={value}
                  onClick={() => {
                    console.log("value", value)
                    if (value === "latest") {
                      generateBanners(activeGeneratedSheet, "latest", actions);
                      setGenerateTab("latest");
                    } else {
                      generateBanners(activeGeneratedSheet, "prev", actions);
                      setGenerateTab("prev");
                    }
                  }}
                >
                  <div className="flex items-center gap-2">
                    {React.createElement(icon, { className: "w-4 h-4 lg:w-5 lg:h-5" })}
                    <p className='whitespace-nowrap text-xs lg:text-sm'>{label}</p>
                  </div>
                </Tab>
              ))}
            </TabsHeader>

            {/* <TabsBody placeholder={{}}>
              {data.map(({ value, desc }) => (
                // console.log("desc", desc),
                <TabPanel key={value} value={value}>
                  {desc}
                </TabPanel>
              ))}
            </TabsBody> */}
          </Tabs>
        )
      }
      <div css={{ display: 'flex', alignItems: 'stretch' }}>
        <div className='flex items-center'>
          <div
            css={{
              margin: '0 16px',
              cursor: 'pointer',
              color: '#fff',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              ':hover': {
                textDecoration: 'underline',
              }
            }}
            onClick={() => uploadRef.current?.click()}
          >

            <input
              ref={uploadRef}
              accept="application/json"
              css={{ display: 'none' }}
              type="file"
              onChange={handleImport}
            />
            Import
          </div>
          <div
            css={{
              margin: '0 16px',
              cursor: 'pointer',
              color: '#fff',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              ':hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={() => handleExport()}
          >
            Export
          </div>
        </div>
        <div className='flex gap-2'>
          <div
            className='text-sm bg-[#3a3a4c] hover:bg-[rgba(58,58,76,0.5)] text-white p-2 rounded-md flex items-center gap-2'
            onClick={openPreview}
          >
            <div css={{ fontSize: 20 }}>
              <PlayCircleIcon />
            </div>{' '}
            <span className='hidden md:block'>Preview</span>
          </div>

          <button className='
          text-sm bg-[#3a3a4c] hover:bg-[rgba(58,58,76,0.5)] text-red-600  h-full p-2 rounded-md flex items-center gap-2'
            onClick={() => {
              localStorage.removeItem('accessToken');
              navigate('/login');
            }}
          >
            <MdLogout className='w-6 h-6' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(HeaderLayout);