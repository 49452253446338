import XIcon from '@duyank/icons/regular/X';
import { useEditor } from 'lidojs/design-editor';
import React, { FC } from 'react';
import { useMainContext } from 'src/context/MainContext';
import { extractTextFromHTML } from 'src/utils/htmlParse';

const LayerContent: FC<{
  onClose: () => void,
}> = ({ onClose }) => {
  const { state, actions, query } = useEditor();
  const { extras, setExtras } = useMainContext();

  const layers = query.serialize();

  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'auto',
        display: 'flex',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexShrink: 0,
          height: 48,
          borderBottom: '1px solid rgba(57,76,96,.15)',
          padding: '0 20px',
        }}
      >
        <p
          css={{
            lineHeight: '48px',
            fontWeight: 600,
            color: '#181C32',
            flexGrow: 1,
          }}
        >
          Layers (Identifiers)
        </p>
        <div
          css={{
            fontSize: 20,
            flexShrink: 0,
            width: 32,
            height: 32,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={onClose}
        >
          <XIcon />
        </div>
      </div>
      <div css={{ padding: '16px' }}>
        {
          Object.entries(layers[0].layers)?.map(([key, value]: [any, any], index) => {
            if (value.type?.resolvedName === 'TextLayer' || value.type?.resolvedName === 'ImageLayer') {
              return (
                <div key={index} className='border-b border-gray-300 py-4' onMouseOver={()=>{
                  actions.hoverLayer(0, key);
                }}>
                  <div className='text-xs text-gray-600'>Key : {key}</div>

                  <p className='font-bold text-violet-400'>{value.type.resolvedName}</p>

                  <div>
                    {
                      value.type?.resolvedName === 'TextLayer' && <p className='text-black font-bold text-lg'>{extractTextFromHTML(value.props.text)?.join(" ")}</p>
                    }
                    {
                      value.type?.resolvedName === 'ImageLayer' && <div className='w-full h-32'>
                        <img src={value.props?.image?.url} alt="" className='w-full h-full object-contain' />
                      </div>
                    }
                  </div>


                  {/* add field identifier to all the objects */}
                  {
                    ((state.pages[0].layers[key].data?.type === 'Text' || state.pages[0].layers[key].data?.type === 'Image') && <input
                      type="text"
                      className='border border-gray-300 rounded-md p-2 mt-2 w-full'
                      placeholder='Identifier'
                      value={
                        extras[key]?.identifier
                      }
                      onChange={(e) => {
                        console.log("stateeeeeeee", state);
                        setExtras({
                          ...extras,
                          [key]: {
                            identifier: e.target.value,
                            id: key
                          }
                        })
                      }}
                    />)
                  }
                  {/* <div className='text-sm'>
                      {JSON.stringify(value, null, 2)}
                    </div> */}
                </div>
              )
            }
            return null;
          })
        }
      </div>
    </div>
  );
};

export default LayerContent;
