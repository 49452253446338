import { DesignFrame } from 'lidojs/design-editor';
import React, { useEffect, useState } from 'react';
import { data } from './data';
import { useEditor } from 'lidojs/design-editor';
import { useSearchParams } from 'react-router-dom';
import axios from 'src/utils/axiosInterceptorInstance';
import toast from 'react-hot-toast';
import { useMainContext } from 'src/context/MainContext';

const EditorContent = () => {

  const { actions, query, state } = useEditor();
  const [params] = useSearchParams();

  const [countScaleChanges, setCountScaleChanges] = useState(0);
  const { setTemplate, extras, template } = useMainContext();
  useEffect(() => {
    setCountScaleChanges((prev) => prev + 1);
    console.log("scale", state.scale, countScaleChanges);
    console.log("state", state);
    if (countScaleChanges === 1) {
      if (state.scale > 0.52) {
        actions.setScale(0.52);
      }
    }
  }, [state.scale]);

  useEffect(() => {
    console.log("pages", state.pages);
    actions.setActivePage(0);
  }, [state.pages.length]);

  useEffect(() => {
    if (state.pages.length > 0) {
      if (template) {
        localStorage.setItem('lastOpenTemplate', JSON.stringify(template));
      }
      localStorage.setItem("designData", JSON.stringify(query.serialize()));
    }
  }, [state]);

  const loadTemplate = async (id: string) => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/detail/${id}`);
      const data = response.data?.data?.layers;
      setTemplate(response.data?.data);
      actions.setData([{
        layers: data,
      }]);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const templateId = params.get("sharedtemplateid");
    if (templateId) {
      loadTemplate(templateId);
    } else if (localStorage.getItem("designData")) {
      if (localStorage.getItem('lastOpenTemplate')) {
        const lastTemplate = localStorage.getItem('lastOpenTemplate');
        setTemplate(JSON.parse(lastTemplate));
      }
      actions.setData(JSON.parse(localStorage.getItem("designData") || ""));
    }
  }, []);

  return <DesignFrame data={data} />;
};

export default EditorContent;
