import axios, { AxiosRequestHeaders, RawAxiosRequestHeaders } from 'axios';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

const axiosInterceptorInstance = axios.create({});


// Request interceptor
axiosInterceptorInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here (add headers, authentication tokens)
    // const uid = localStorage.getItem("accessToken");
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);
// End of Request interceptor



// Response interceptor
axiosInterceptorInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here
    return response;
  },
  (error) => {
    // Handle response errors here
    // check for error 401 and redirect to login page
    console.log("error", error);
    if (error.response?.data?.statusCode === 401 || error.response?.status === 401 || error.response?.data?.statusCode === 403 || error.response?.status === 403) {
      // redirect to login page
      toast.error("Session expired, please login again!", {
        id: "session-expired"
      });
      setTimeout(() => {
        window.location.href = "/login";
        localStorage.removeItem("accessToken");
      }, 2000);
    }
    return Promise.reject(error);
  }
);
// End of Response interceptor

export default axiosInterceptorInstance;