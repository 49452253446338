import { FontData } from 'lidojs/design-core';
import { Editor, GetFontQuery, PageControl } from 'lidojs/design-editor';
import { useCallback, useEffect, useRef, useState } from 'react';
import AppLayerSettings from './layout/AppLayerSettings';
import HeaderLayout from './layout/HeaderLayout';
import Sidebar from './layout/Sidebar';
import EditorContent from './pages/EditorContent';
import PreviewModal from './PreviewModal';
import axios from './utils/axiosInterceptorInstance';
import Trials from './pages/Trials';

const Test = () => {
  const leftSidebarRef = useRef<HTMLDivElement>(null);
  const [openPreview, setOpenPreview] = useState(false);

  // const fontInputRef = useRef<HTMLInputElement>(null);
  const [fontInputRef, setFontInputRef] = useState<HTMLInputElement | null>(null);

  const getFonts = useCallback((query: GetFontQuery) => {
    const buildParams = (data: Record<string, string | string[]>) => {
      const params = new URLSearchParams();

      // utilising this function to get the fotn input ref if it does not exist
      if (!fontInputRef) {
        if (process.env.NODE_ENV === 'production') {
          const chevrons = document.getElementsByClassName('css-uwwqev');
          console.log("font input in prod", chevrons);
          if (chevrons) {
            const time = setTimeout(() => {
              setFontInputRef(chevrons[2] as HTMLInputElement);
            }, 0);
          }
        } else {
          const inputField = document.getElementsByClassName('css-1lqeo7t-G1');
          console.log("font input in dev", inputField);
          if (inputField) {
            const time = setTimeout(() => {
              setFontInputRef(inputField[0] as HTMLInputElement);
              console.log("fontInputRef", fontInputRef);
            }, 0);
          }
        }
      }

      Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => params.append(key, v));
        } else {
          params.append(key, value);
        }
      });

      return params;
    };
    return axios
      .get(`${process.env.NEXT_PUBLIC_BASE_URL}/fonts?${buildParams({
        skip: query.offset,
        limit: query.limit,
        q: query.q
      })}`)
      .then((res) => {
        console.log(res.data?.data);
        const temp = res.data?.data?.map((font: any) => {
          let obj = {
            fonts: [],
            name: font.name
          };
          for (let i = 0; i < font.fonts.length; i++) {
            obj.fonts.push({
              urls: [font.fonts[i].url],
              style: font.fonts[i].style
            });
          }
          return obj;
        });
        // return res.data as FontData;
        return temp as FontData[];
      });
  }, []);
  const [viewPortHeight, setViewPortHeight] = useState<number>();
  useEffect(() => {
    const windowHeight = () => {
      setViewPortHeight(window.innerHeight);
    };
    window.addEventListener('resize', windowHeight);
    windowHeight();
    return () => {
      window.removeEventListener('resize', windowHeight);
    };
  }, []);

  useEffect(() => {
    console.log("fontInputRef in useEffect", fontInputRef);
    if (fontInputRef) {
        fontInputRef.addEventListener('change', () => {
          console.log('fontInputRef clicked');
        });
      return () => {
        fontInputRef.removeEventListener('change', () => {
          console.log('fontInputRef clicked');
        });
      }
    }
  }, [fontInputRef]);

  return (
    <Editor
      config={{
        assetPath: './assets',
        frame: {
          defaultImage: {
            url: `./assets/images/frame-placeholder.png`,
            width: 1200,
            height: 800,
          },
        },
      }}
      getFonts={getFonts}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          height: '100vh',
          maxHeight: viewPortHeight ? `${viewPortHeight}px` : 'auto',
        }}
      >
        <HeaderLayout openPreview={() => setOpenPreview(true)} />
        {openPreview && <PreviewModal onClose={() => setOpenPreview(false)} />}
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            flex: 'auto',
            overflow: 'auto',
            background: '#EBECF0',
            '@media (max-width: 900px)': {
              flexDirection: 'column-reverse',
            },
          }}
        >
          <div
            ref={leftSidebarRef}
            css={{
              display: 'flex',
              flexDirection: 'row',
              background: 'white',
            }}
          >
            <Sidebar />
          </div>
          <div
            css={{
              flexGrow: 1,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <AppLayerSettings />
            <div
              css={{
                flexGrow: 1,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column'
              }}
              id='editor-content'
            >
              <EditorContent />
            </div>
            <div
              css={{
                height: 40,
                background: '#fff',
                borderTop: '1px solid rgba(57,76,96,.15)',
                display: 'grid',
                alignItems: 'center',
                flexShrink: 0,
                '@media (max-width: 900px)': {
                  display: 'none',
                },
              }}
            >
              <PageControl />
            </div>
          </div>
        </div>
      </div>
    </Editor>
  );
};

export default Test;
