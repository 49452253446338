import { Navigate } from "react-router-dom";
import { useMainContext } from "src/context/MainContext";

export const ProtectedRoute = ({ children }) => {
    const { user } = useMainContext();

    // check it accessToken is present in local storage
    if(localStorage.getItem('accessToken')) {
        return children;
    }
    return <Navigate to="/login" />;
};