import XIcon from '@duyank/icons/regular/X';
import { Preview } from 'lidojs/design-editor';
import React, { FC, useEffect, useState } from 'react';

interface PreviewModalProps {
  onClose: () => void;
}

const PreviewModal: FC<PreviewModalProps> = ({ onClose }) => {


  const [leftChevron, setLeftChevron] = useState(null);
  const [rightChevron, setRightChevron] = useState(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const chevrons = document.getElementsByClassName('css-1ylfn77');
      console.log("chevrons in prod", chevrons);
      if (chevrons) {
        const time = setTimeout(() => {
          setLeftChevron(chevrons[0]);
          setRightChevron(chevrons[1]);
        }, 0);

        return () => {
          clearInterval(time);
        }
      }
    } else {
      const chevrons = document.getElementsByClassName('css-faa32z-G1');
      console.log("chevrons in dev", chevrons);
      if (chevrons) {
        const time = setTimeout(() => {
          setLeftChevron(chevrons[0]);
          setRightChevron(chevrons[1]);
        }, 0);

        return () => {
          clearInterval(time);
        }
      }
    }
  }, []);

  useEffect(() => {
    // add event listener to chevrons leftarrow and rightarrow
    if (leftChevron && rightChevron) {
      console.log("leftChevron", leftChevron);
      console.log("rightChevron", rightChevron);

      leftChevron.addEventListener('click', () => {
        console.log("leftChevron clicked");
      });
      document.addEventListener('keydown', (e) => {
        console.log("e.key", e.key);
        if (e.key === 'ArrowLeft') {
          leftChevron.click();

        } else if (e.key === 'ArrowRight') {
          rightChevron.click();
        }
      });
    }

    if (leftChevron) {
      leftChevron.addEventListener('click', () => {
        console.log('leftChevron clicked');
      });
    }
    if (rightChevron) {
      rightChevron.addEventListener('click', () => {
        console.log('rightChevron clicked');
      });
    }
    return () => {
      window.removeEventListener('keydown', (e) => {
        if (e.key === 'ArrowLeft') {
          leftChevron.click();
        } else if (e.key === 'ArrowRight') {
          rightChevron.click();
        }
      });

      if (leftChevron) {
        leftChevron.removeEventListener('click', () => {
          console.log('leftChevron clicked');
        });
      }
      if (rightChevron) {
        rightChevron.removeEventListener('click', () => {
          console.log('rightChevron clicked');
        });
      }
    };
  }, [leftChevron, rightChevron]);


  return (
    <div
      css={{
        position: 'fixed',
        inset: 0,
        zIndex: 1040,
        background: 'rgba(13,18,22,.95)',
      }}
    >
      <Preview />
      <div
        css={{
          background: 'rgba(255,255,255,0.3)',
          width: 60,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          right: 24,
          top: 24,
          borderRadius: '50%',
          fontSize: 36,
          color: '#fff',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <XIcon />
      </div>
    </div>
  );
};

export default PreviewModal;
