import ReactGA from 'react-ga4';
import Test from './src/Test';
import { MainContextProvider } from 'src/context/MainContext';
import { Toaster } from 'react-hot-toast';
import { useMainContext } from 'src/context/MainContext';
import { Routes, Route } from "react-router-dom";
import { LoginPage } from 'src/pages/Login';
import { ProtectedRoute } from 'src/components/ProtectedRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Trials from 'src/pages/Trials';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-68BJDBYMLE');
}
const App = () => {

  const { sheetCreateLoading } = useMainContext();
  return <GoogleOAuthProvider clientId='631253581023-sa7522f96kobo2ftklp2jsa3meo55nvb.apps.googleusercontent.com'>
      <Toaster />
      <Routes>
        <Route path="/" element={
           <MainContextProvider>
          <ProtectedRoute>
            <Test />
          </ProtectedRoute>
          </MainContextProvider>
        } />
        <Route path="login" element={<LoginPage />} />
        {/* <Route  path="try" element={<Trials />} /> */}
      </Routes>
    </GoogleOAuthProvider>
};

export default App;
