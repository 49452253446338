import { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
// import { useMainContext } from "src/context/MainContext";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "src/utils/axiosInterceptorInstance";
import toast from "react-hot-toast";

export const LoginPage = () => {

  // const { setUser } = useMainContext();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const loginUser = async (credentialResponse) => {
    setLoading(true);
    try {
      const res = await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/auth/google`, {
        id_token: credentialResponse?.credential
      })
      console.log(res.data.data);
      // setUser(res.data.data?.user);
      localStorage.setItem('accessToken', res.data.data?.accessToken);
      toast.success('Login Successful!', {
        id: 'login-success'
      });
      setTimeout(() => {
        setLoading(false);
        navigate('/');
      }, 1000);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error('Login Failed!',{
        id: 'login-failed'
      });
    }
  }


  return (
    <div className="w-screen h-screen grid place-items-center relative">
      {
        loading && (
          <div className="absolute inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        )
      }
      <GoogleLogin
        onSuccess={credentialResponse => {
          console.log(credentialResponse);

          loginUser(credentialResponse)
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </div>
  );
};