import { TbRestore } from 'react-icons/tb';
import XIcon from '@duyank/icons/regular/X';

import React, { useMemo, useRef, useState, useEffect } from "react";
import PolygonAnnotation from "../../components/PolygonAnnotation";
import { Stage, Layer, Image } from "react-konva";
import { exportStageSVG } from 'react-konva-to-svg'
import { useEditor } from 'lidojs/design-editor';
import { ImUndo2 } from 'react-icons/im';

type FrameCreatorType = {
    setFrameCreatorOpen: (open: boolean) => void;
}




const videoSource = "./assets/images/frame-placeholder.png";
const wrapperStyle = {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
    backgroundColor: "aliceblue",
};
const columnStyle = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20,
    backgroundColor: "aliceblue",
};

const FrameCreator = ({ setFrameCreatorOpen }: FrameCreatorType) => {


    const [image, setImage] = useState<HTMLElement>();
    const imageRef = useRef(null);
    const stageRef = useRef(null);
    const { actions } = useEditor();
    const [points, setPoints] = useState([]);
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });
    const [flattenedPoints, setFlattenedPoints] = useState();
    const [position, setPosition] = useState([0, 0]);
    const [isMouseOverPoint, setMouseOverPoint] = useState(false);
    const [isPolyComplete, setPolyComplete] = useState(false);
    const [canvasHeight, setCanvasHeight] = useState(400);
    const [canvasWidth, setCanvasWidth] = useState(400);
    const videoElement = useMemo(() => {
        const element = new window.Image();
        // element.width = 650;
        // element.height = 302;
        element.src = videoSource;
        return element;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoSource]); //it may come from redux so it may be dependency that's why I left it as dependecny...
    useEffect(() => {
        const onload = function () {

            // set image dimensions such that it fits the canvas width and height (canvasHeight, canvasWidth) always with aspect ratio without stretching
            const imgWidht = videoElement.width;
            const imgHeight = videoElement.height;
            console.log("img", videoElement.width, videoElement.height);
            if (imgWidht > imgHeight) {
                videoElement.width = canvasWidth;
                videoElement.height = (imgHeight * canvasWidth) / imgWidht;
                setCanvasHeight(videoElement.height);
            } else {
                videoElement.height = canvasHeight;
                videoElement.width = (imgWidht * canvasHeight) / imgHeight;
                setCanvasWidth(videoElement.width);
            }

            console.log("img", videoElement.width, videoElement.height);

            setSize({
                width: videoElement.width,
                height: videoElement.height,
            });
            setImage(videoElement);
            imageRef.current = videoElement;
        };
        videoElement.addEventListener("load", onload);
        return () => {
            videoElement.removeEventListener("load", onload);
        };
    }, [videoElement]);


    const uploadImage = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function (event) {
            const img = new window.Image();
            img.src = event.target.result as string;
            img.onload = function () {

                // set image dimensions such that it fits the canvas width and height (canvasHeight, canvasWidth) always with aspect ratio without stretching
                const imgWidht = img.width;
                const imgHeight = img.height;
                console.log("img", img.width, img.height);
                if (imgWidht > imgHeight) {
                    img.width = canvasWidth;
                    img.height = (imgHeight * canvasWidth) / imgWidht;
                    setCanvasHeight(img.height);
                } else {
                    img.height = canvasHeight;
                    img.width = (imgWidht * canvasHeight) / imgHeight;
                    setCanvasWidth(img.width);
                }

                console.log("img", img.width, img.height);

                setSize({
                    width: img.width,
                    height: img.height,
                });
                setImage(img);
                imageRef.current = img;
            };
        };
        reader.readAsDataURL(file);
    }

    useEffect(() => {
        console.log("img eff", size.width, size.height);
    }, [size]);

    const getMousePos = (stage) => {
        return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    };
    //drawing begins when mousedown event fires.
    const handleMouseDown = (e) => {
        if (isPolyComplete) return;
        const stage = e.target.getStage();
        const mousePos = getMousePos(stage);
        if (isMouseOverPoint && points.length >= 3) {
            setPolyComplete(true);
        } else {
            setPoints([...points, mousePos]);
        }
    };

    const handleMouseMove = (e) => {
        const stage = e.target.getStage();
        const mousePos = getMousePos(stage);
        setPosition(mousePos);
    };
    const handleMouseOverStartPoint = (e) => {
        if (isPolyComplete || points.length < 3) return;
        e.target.scale({ x: 3, y: 3 });
        setMouseOverPoint(true);
    };
    const handleMouseOutStartPoint = (e) => {
        e.target.scale({ x: 1, y: 1 });
        setMouseOverPoint(false);
    };
    const handlePointDragMove = (e) => {
        const stage = e.target.getStage();
        const index = e.target.index - 1;
        const pos = [e.target._lastPos.x, e.target._lastPos.y];
        if (pos[0] < 0) pos[0] = 0;
        if (pos[1] < 0) pos[1] = 0;
        if (pos[0] > stage.width()) pos[0] = stage.width();
        if (pos[1] > stage.height()) pos[1] = stage.height();
        setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
    };

    useEffect(() => {
        setFlattenedPoints(
            points
                .concat(isPolyComplete ? [] : position)
                .reduce((a, b) => a.concat(b), [])
        );
    }, [points, isPolyComplete, position]);
    const undo = () => {
        setPoints(points.slice(0, -1));
        setPolyComplete(false);
        setPosition(points[points.length - 1]);
    };
    const reset = () => {
        setPoints([]);
        setPolyComplete(false);
    };

    const toSVG = async () => {

        const stage = stageRef.current.getStage();

        console.log("stage layers", stage.getLayers()[0]);
        const svg = await exportStageSVG(stage, false, {
            onBefore: ([stage, layer]) => {
                // Perform actions before export
                console.log("onBefore");
                console.log("stage", stage);
                console.log("layer", layer);
                const imageNode = stage.getLayers()[0].findOne('Image');
                console.log("imageNode", imageNode);
                imageNode.hide()


                // fing all Circles and hide them
                const circles = stage.find('Circle');

                circles.forEach((circle) => {
                    circle.hide()
                })

            },
            // Perform actions before export
            onAfter: ([stage, layer]) => {
                // Perform actions after export
                console.log("onAfter");
                const imageNode = stage.getLayers()[0].findOne('Image');

                imageNode.show()

                // fing all Circles and show them
                const circles = stage.find('Circle');

                circles.forEach((circle) => {
                    circle.show()
                })
            }
        });

        // parse the svg and get the width and height and d of the path
        const parser = new DOMParser();
        const doc = parser.parseFromString(svg, "image/svg+xml");
        const svgNode = doc.querySelector("svg");
        const width = svgNode.getAttribute("width");
        const height = svgNode.getAttribute("height");
        const path = doc.querySelector("path").getAttribute("d");

        console.log("svg", svg);
        console.log("width", width);
        console.log("height", height);
        console.log("path", path);


        const obj = {
            width,
            height,
            path
        }

        actions.addFrameLayer(obj, path);
    };

    const handleGroupDragEnd = (e) => {
        //drag end listens other children circles' drag end event
        //...that's, why 'name' attr is added, see in polygon annotation part
        if (e.target.name() === "polygon") {
            let result = [];
            let copyPoints = [...points];
            copyPoints.map((point) =>
                result.push([point[0] + e.target.x(), point[1] + e.target.y()])
            );
            e.target.position({ x: 0, y: 0 }); //needs for mouse position otherwise when click undo you will see that mouse click position is not normal:)
            setPoints(result);
        }
    };

    const handleSvgImport = (e) => {
        const file = e.target.files[0];
        console.log("svg file", file)

        // get svg path and width and height
        const svg = new window.XMLHttpRequest();
        svg.open("GET", URL.createObjectURL(file), true);
        svg.send();
        svg.onload = function () {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svg.responseText, "image/svg+xml");
            const svgNode = doc.querySelector("svg");
            const path = doc.querySelector("path").getAttribute("d");
            console.log("svg", svg.responseText);
            const width = svgNode.getAttribute("width");
            const height = svgNode.getAttribute("height");

            const obj = {
                width: Number(width.replace('px', '')),
                height: Number(height.replace('px', '')),
                path
            }

            actions.addFrameLayer(obj, path);
        };
        
    }

    return (
        <div
            css={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0,0,0,.7)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 100,
                color: '#fff',
            }}
        >
            <div
                css={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    cursor: 'pointer',
                }}
                onClick={() => setFrameCreatorOpen(false)}
            >
                <XIcon fontSize={24} />
            </div>

            {/* Frame Creator */}
            <div className="reset-wrapper flex flex-col gap-4 w-full p-2">

                {/* Upload BG Image */}
                <div className='flex gap-6'>
                <div className="w-fit h-10">
                    <input
                        id='file-upload'
                        type="file"
                        accept="image/*"
                        onChange={uploadImage}
                        className='hidden'
                    />
                    <label
                        className="bg-blue-500 text-white rounded-md p-2"
                        htmlFor='file-upload'
                    >
                        Upload Image
                    </label>
                </div>
                <div className="w-fit h-10">
                    <input
                        id='svg-upload'
                        type="file"
                        accept=".svg"
                        onChange={handleSvgImport}
                        className='hidden'
                    />
                    <label
                        className="bg-blue-500 text-white rounded-md p-2"
                        htmlFor='svg-upload'
                    >
                        Upload Svg
                    </label>
                </div>
                </div>

                {/* Frame Creator  */}
                <Stage
                    width={canvasWidth}
                    height={canvasHeight}
                    onMouseMove={handleMouseMove}
                    onMouseDown={handleMouseDown}
                    ref={stageRef}
                >
                    <Layer>
                        <Image
                            ref={imageRef}
                            image={image}
                            x={0}
                            y={0}
                            width={size.width}
                            height={size.height}
                        />
                        <PolygonAnnotation
                            points={points}
                            flattenedPoints={flattenedPoints}
                            handlePointDragMove={handlePointDragMove}
                            handleGroupDragEnd={handleGroupDragEnd}
                            handleMouseOverStartPoint={handleMouseOverStartPoint}
                            handleMouseOutStartPoint={handleMouseOutStartPoint}
                            isFinished={isPolyComplete}
                        />
                    </Layer>
                </Stage>


                <div className="flex gap-2 max-w-[400px]">
                    {/* <div className="w-auto h-9 rounded-full overflow-hidden my-1">
                        <input
                            title="Color"
                            className="w-[200%] h-[200%] bg-transparent border-none cursor-pointer appearance-none transform-cpu -translate-x-1/4 -translate-y-1/4"
                            type="color"
                            value={strokeColor}
                            onChange={onColorChange}
                        />
                    </div> */}
                    {/* <button
                        title="Pencil"
                        className={`${iconButton} ${pencilSelected} my-1`}
                        type="button"
                        aria-label="pencil"
                        onClick={handlePencilClick}
                    >
                        <GrEdit />
                    </button> */}
                    <button
                        title="Undo"
                        className=" flex items-center gap-1 p-2 rounded-md my-1 border-2 border-gray-300 bg-transparent hover:bg-gray-50 text-white"
                        type="button"
                        aria-label="eraser"
                        onClick={undo}
                    >
                        <ImUndo2 /> Undo
                    </button>
                    <button
                        title="Reset"
                        className="flex items-center gap-1 p-2 rounded-md my-1 border-2 border-gray-300 bg-transparent hover:bg-gray-50 text-white"
                        type="button"
                        aria-label="clear"
                        onClick={reset}
                    >
                        <TbRestore /> Reset
                    </button>

                    <button
                        css={{
                            padding: '8px 16px',
                            background: '#6497eb',
                            color: '#fff',
                            border: 'none',
                            borderRadius: 4,
                            cursor: 'pointer',
                            marginLeft: 'auto',
                        }}
                        onClick={toSVG}
                    >
                        Okay
                    </button>
                </div>
            </div>
        </div >
    );
}

export default FrameCreator;