import XIcon from '@duyank/icons/regular/X'
import React from 'react'
import toast from 'react-hot-toast'
import { LuCopy } from 'react-icons/lu'
import { useMainContext } from 'src/context/MainContext'
import axios from 'src/utils/axiosInterceptorInstance'
type Props = {}

const PreviewBatchModal = (props: Props) => {

    const { updateBatchResponse, setUpdateBatchResponse, setSheetCreateLoading, activeGeneratedSheet } = useMainContext()

    const updateBannersToPWLive = async () => {
        try {
            setSheetCreateLoading(true)
            const res = await axios.patch(`${process.env.NEXT_PUBLIC_BASE_URL}/template/update-batch`, {
                sheetId: activeGeneratedSheet,
                start: updateBatchResponse?.start,
                end: updateBatchResponse?.end
            })

            setUpdateBatchResponse(null)
            setSheetCreateLoading(false)
            toast.success(res.data.message || 'Batch updated successfully')
        } catch (err) {
            setSheetCreateLoading(false)
            toast.error(err.response.data.message || 'Something went wrong')
        }
    }

    return (
        <div
            className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center'
        >
            <div
                className='bg-white rounded-lg w-[90%] h-[90%] relative overflow-hidden'
            >
                <div className='bg-white p-4 w-full h-full overflow-y-auto'>
                    <div className='flex justify-between items-center'>
                        <h1 className='text-xl md:text-3xl font-bold tracking-tight text-gray-900'>Preview Batch</h1>
                    </div>
                    <div
                        className="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
                        role="alert"
                    >
                        <svg
                            className="flex-shrink-0 inline w-4 h-4 me-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className='font-medium'>
                            {updateBatchResponse?.message}
                        </div>
                    </div>

                    <div className='mt-4 mb-16
                    grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4
                '>
                        {updateBatchResponse?.data?.map((batch, idx) => (
                            <div key={idx} className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow">
                                <a href="#">
                                    <img
                                        className="p-8 rounded-t-lg hover:border border-gray-200 hover:scale-95 transition-transform duration-300 ease-in-out"
                                        src={batch.image}
                                        alt="product image"
                                    />
                                </a>
                                <div className="px-5 pb-5">
                                    <h5 className="text-xl font-semibold tracking-tight text-gray-400">
                                        {batch.batchId}
                                        <LuCopy onClick={() => {
                                            navigator.clipboard.writeText(batch.batchId)
                                            toast.success('Copied to clipboard')
                                        }} className='inline text-blue-600 hover:text-gray-500 ml-2 w-4 h-4' />
                                    </h5>
                                    <div className="flex items-center justify-between">
                                        <div className='flex items-center gap-1'>
                                            <span className="text-3xl font-bold text-gray-900">
                                                Rs.{batch.price}
                                            </span>
                                            <span className="text-sm font-semibold text-gray-600 line-through">
                                                Rs.{batch.discoundedPrice}
                                            </span>
                                        </div>
                                        <a
                                            href="#"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                        >
                                            Add to cart
                                        </a>
                                    </div>
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
                <div className='flex justify-end mt-4 p-4 sticky bottom-0 bg-white'>
                    <button
                        onClick={() => setUpdateBatchResponse(null)}
                        className='bg-red-500 hover:bg-red-300 text-white px-4 py-2 rounded-lg'
                    >
                        Cancel
                    </button>
                    <button
                        onClick={updateBannersToPWLive}
                        className='bg-blue-500 hover:bg-blue-300 text-white px-4 py-2 rounded-lg ml-4'
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PreviewBatchModal