import { v4 as uuidv4 } from 'uuid';


const toPanvaJSON = (data: any) => {

    // console.log(data);
    RootFramer(data[0]);
    solveLayers(data);

    const newObject = {
        "layers": finalObj
    }
    const arr = [newObject];

    return arr;
}







let finalObj = {}

const getHtmlText = (data) => {
    console.log(data);
    /*
    {
    "type": "FontCustom",
    "left": 41,
    "top": 84,
    "width": 378,
    "height": 50,
    "fill": "rgb(10,15,67)",
    "fontSize": 68.45,
    "fontFamily": "Poppins-Bold",
    "text": "electrical",
    "textAlign": "center",
    "charSpacing": -25,
    "name": "electrical"
}
    */
    const pTags = data.text.split("\r");
    let html = '';
    for (let i = 0; i < pTags.length; i++) {
        html += `<p style=\"text-align: ${data.textAlign};font-family: Blinker;font-size: ${data.fontSize}px;color: ${data.fill};line-height: 1;letter-spacing: ${data.charSpacing};\">${pTags[i]}</p>`;
    }
    return html;
}

const textFramer = (data) => {
    const id = uuidv4();
    finalObj["ROOT"].child.push(id);

    finalObj[id] = {
        "type": {
            "resolvedName": "TextLayer"
        },
        "props": {
            "text": getHtmlText(data),
            "fonts": [
                {
                    "name": "Blinker",
                    "fonts": [
                        {
                            "urls": [
                                "https://d2bps9p1kiy4ka.cloudfront.net/5b09189f7285894d9130ccd0/b58270b3-a9fa-4f60-9242-1e21eeb7d2b2.woff2"
                            ]
                        },
                        {
                            "urls": [
                                "https://d2bps9p1kiy4ka.cloudfront.net/5b09189f7285894d9130ccd0/87654452-0059-4045-af16-ff0581c0c510.woff2"
                            ]
                        }
                    ]
                }
            ],
            "scale": 1,
            "colors": [
                data.fill
            ],
            "effect": {
                "name": "echo",
                "settings": {
                    "color": data.fill,
                    "offset": 0,
                    "direction": 0
                }
            },
            "rotate": 0,
            "boxSize": {
                "x": data.left,
                "y": data.top,
                "width": data.width,
                "height": data.height
            },
            "position": {
                "x": data.left,
                "y": data.top
            },
            "fontSizes": [
                data.fontSize
            ]
        },
        // "props": {
        //     "text": "<p style=\"text-align: center;font-family: Blinker;font-size: 64px;color: rgb(0, 0, 0);line-height: 1.4;letter-spacing: 0em;\"><span style=\"color: rgb(0, 0, 0);\">2024</span></p>",
        //     "position": {
        //         "x": 223.80278377315483,
        //         "y": 276.8693894774998
        //     },
        //     "boxSize": {
        //         "width": 536.3009995574356,
        //         "height": 90,
        //         "x": 523.1390728476821,
        //         "y": 259.9867549668875
        //     },
        //     "scale": 1,
        //     "rotate": 358.84200043996503,
        //     "fonts": [
        //         {
        //             "name": "Blinker",
        //             "fonts": [
        //                 {
        //                     "urls": [
        //                         "https://d2bps9p1kiy4ka.cloudfront.net/5b09189f7285894d9130ccd0/b58270b3-a9fa-4f60-9242-1e21eeb7d2b2.woff2"
        //                     ]
        //                 },
        //                 {
        //                     "urls": [
        //                         "https://d2bps9p1kiy4ka.cloudfront.net/5b09189f7285894d9130ccd0/87654452-0059-4045-af16-ff0581c0c510.woff2"
        //                     ]
        //                 }
        //             ]
        //         }
        //     ],
        //     "colors": [
        //         "rgb(0, 0, 0)"
        //     ],
        //     "fontSizes": [
        //         64
        //     ]
        // },
        "locked": false,
        "child": [],
        "parent": "ROOT",
        // "textData": [
        //     data.text
        // ]
    }
}

const imageFramer = async (data) => {
    const id = uuidv4();
    finalObj["ROOT"].child.push(id);
    // console.log("Opacity: ", data.opacity, "   ", id);
    finalObj[id] = {
        "type": {
            "resolvedName": "ImageLayer"
        },
        "props": {
            "image": {
                "url": data.src,
                "thumb": data.src,
                "boxSize": {
                    "width": data.width,
                    "height": data.height
                },
                "position": {
                    "x": 0,
                    "y": 0
                },
                "rotate": 0
            },
            "position": {
                "x": data.left,
                "y": data.top,
            },
            "boxSize": {
                "width": data.width,
                "height": data.height
            },
            "rotate": 0,
        },
        "locked": false,
        "child": [],
        "parent": "ROOT"
    }
    if (data.opacity) {
        finalObj[id].props["transparency"] = 1 - data.opacity;
    }
}

const RootFramer = (data) => {

    finalObj["ROOT"] = {
        "type": {
            "resolvedName": "RootLayer"
        },
        "props": {
            "boxSize": {
                "width": data.width,
                "height": data.height
            },
            "position": {
                "x": 0,
                "y": 0
            },
            "rotate": 0,
            "color": "rgb(255, 255, 255)",
            "image": null
        },
        "locked": false,
        "child": [],
        "parent": null
    }
}

const solveLayers = (data) => {
    let cnt = 0;
    for (let i = 0; i < data.length; i++) {
        if (!data[i] || !data[i].type || data[i].visible === false) continue;
        if (data[i].type === "Image") {
            cnt++;
            imageFramer(data[i]);
        } else if (data[i].type === "FontCustom") {
            textFramer(data[i]);
        }
        else if (data[i].type === "group") {
            // solveLayers(data[i].objects, data[i]?.id);
            solveLayers(data[i].objects);
        } else {
            console.log(data[i].type)
        }

    }
    console.log(cnt);
}

export default toPanvaJSON;
