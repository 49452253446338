import axios from 'src/utils/axiosInterceptorInstance'
import React, { useContext, createContext, useState, useEffect } from "react"
import toast from "react-hot-toast";
import { Extras, UpdateBatchResponseType, UserType } from "src/types";
import { convertHtmlToText } from "src/utils/htmlParse";
import { v4 as uuid } from "uuid";
import { SerializedLayers } from 'lidojs/design-core';


interface Template {
    _id: string;
    createdAt: string;
    extras: Extras[];
    layers: SerializedLayers;
    sheetId: string;
    templateImage: string;
    templateName: string;
    private: boolean;
    shared: boolean;
}

type MainContextType = {
    extras: Extras,
    setExtras: (extras: Extras) => void,
    sheetCreateLoading?: boolean,
    setSheetCreateLoading?: (loading: boolean) => void,
    rows?: string[][],
    setRows?: (rows: string[][]) => void,
    user?: UserType,
    setUser?: (user: UserType) => void,
    activeGeneratedSheet?: string,
    setActiveGeneratedSheet?: (sheet: string) => void,
    generateBanners?: (text: string, type: string, actions: any) => void,
    end?: String,
    setEnd?: (end: String) => void,
    start?: String,
    setStart?: (start: String) => void,
    generateTab?: string,
    setGenerateTab?: (tab: string) => void,
    templateKey?: string | null,
    setTemplateKey?: (key: string) => void,
    updateBatchResponse?: UpdateBatchResponseType,
    setUpdateBatchResponse?: (response: UpdateBatchResponseType) => void,
    template?: Template | null,
    setTemplate?: (template: Template) => void
}

const MainContext = createContext<MainContextType>({
    extras: {},
    setExtras: () => { },
    sheetCreateLoading: false,
    setSheetCreateLoading: () => { },
    rows: [],
    setRows: () => { },
    user: null,
    setUser: () => { },
    activeGeneratedSheet: "",
    setActiveGeneratedSheet: () => { },
    generateBanners: () => { },
    end: "",
    setEnd: () => { },
    start: "",
    setStart: () => { },
    generateTab: "latest",
    setGenerateTab: () => { },
    templateKey: null,
    setTemplateKey: () => { },
    updateBatchResponse: null,
    setUpdateBatchResponse: () => { },
    template: null,
    setTemplate: () => { }
})


const MainContextProvider = ({ children }) => {

    const [extras, setExtras] = useState<Extras>({});
    const [sheetCreateLoading, setSheetCreateLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<string[][]>([]);
    const [user, setUser] = useState(null);

    const [activeGeneratedSheet, setActiveGeneratedSheet] = useState<string>("");

    const [currMeta, setCurrMeta] = useState<any>(null);
    const [currIds, setCurrIds] = useState<string[]>([]);
    const [currColumns, setCurrColumns] = useState<string[]>([]);


    const [end, setEnd] = useState<String>("");
    const [start, setStart] = useState<String>("");

    const [generateTab, setGenerateTab] = useState<string>("latest");


    const [templateKey, setTemplateKey] = useState<string | null>(uuid());

    const [updateBatchResponse, setUpdateBatchResponse] = useState<UpdateBatchResponseType>(null);

    const [template, setTemplate] = useState<Template | null>(null);

    const generateBanners = async (text, type, actions) => {

        try {
            setSheetCreateLoading(true)
            const res = type === "prev" ? await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/generate/previous/${text}`) : await axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/template/generate/${text}`);
            console.log("sheet data", res.data?.data);

            const rows = res.data?.data?.rows;
            let ids = []
            let columns = []
            if (type === "latest") {
                ids = rows.shift();
                setCurrIds(ids);
                columns = rows.shift();
                setCurrColumns(columns);
            } else {
                ids = currIds;
                columns = currColumns;
            }

            setEnd(res.data?.data?.end);
            setStart(res.data?.data?.start);

            const len = ids.length;
            const data = rows?.map((row) => {
                const obj = {};
                ids.forEach((id, index) => {
                    if (obj[id]) {
                        obj[id].push(row[index])
                    } else {
                        obj[id] = [row[index]]
                    }
                })
                return obj;
            });

            let meta = res.data?.data?.metaData;

            if (type === "latest" && meta) {
                setCurrMeta(meta)
            }

            if (type === "prev") {
                meta = currMeta;
            }

            const pagesArray = data?.map((item, index) => {
                return getJsonData(meta, item, len);
            })

            pagesArray?.forEach((page: any, index) => {
                console.log("page", page);
                Object.entries(page.layers).forEach(([key, value]: any, index) => {
                    if (value.type.resolvedName === 'TextLayer') {
                        value.props.text = convertHtmlToText(value.props.text, value.textData);
                    }
                })
            })

            setRows(rows);
            setActiveGeneratedSheet(text);
            actions.setData(pagesArray);
            setSheetCreateLoading(false)
        } catch (error) {
            console.log("sheet data error", error);
            toast.error(error.response?.data?.message || "Something went wrong! Please try again.", {
                id: "sheet-data-error"
            });
            setSheetCreateLoading(false)
        }
    }

    function extractFileId(url) {
        // Split the URL by "/"
        const parts = url.split("/");

        // Find the index of "d" in the parts array
        const fileIdIndex = parts.findIndex(part => part === "d");

        // Check if the file ID exists and return it
        if (fileIdIndex !== -1 && fileIdIndex + 1 < parts.length) {
            return parts[fileIdIndex + 1];
        }

        // If the file ID is not found, return null
        return null;
    }


    const getJsonData = (meta, data, len) => {

        console.log("data", data);

        const obj = JSON.parse(JSON.stringify(meta));
        Object.keys(data)?.map((key, i) => {
            if (obj[key]?.type?.resolvedName === "TextLayer") {
                obj[key].textData = data[key]
            }
            if (obj[key]?.type?.resolvedName === "ImageLayer") {
                try {
                    console.log("data[key]", data[key]);
                    if (data[key][0]?.includes("drive.google.com")) {
                        // Extract the file ID from the URL
                        const fileId = extractFileId(data[key][0]);

                        if (fileId) {
                            // Generate the converted URL
                            const convertedUrl = [`https://drive.google.com/thumbnail?id=${fileId}`];
                            obj[key].props.image.url = convertedUrl;
                            obj[key].props.image.thumb = convertedUrl;
                        }

                    } else {
                        obj[key].props.image.url = data[key];
                        obj[key].props.image.thumb = data[key];
                    }
                }
                catch {
                    return null;
                }
            }
        })
        return {
            layers: obj
        }
    }


    // to get the user by access token in local storage
    useEffect(() => {
        const res = axios.get(`${process.env.NEXT_PUBLIC_BASE_URL}/user`);
        res.then((res) => {
            setUser(res.data.data?.user);
        }).catch((error) => {
            console.log("error", error);
        })
    }, [])

    return (
        <MainContext.Provider value={{
            extras,
            setExtras,
            sheetCreateLoading,
            setSheetCreateLoading,
            rows,
            setRows,
            user,
            setUser,
            activeGeneratedSheet,
            setActiveGeneratedSheet,
            generateBanners,
            end,
            setEnd,
            start,
            setStart,
            generateTab,
            setGenerateTab,
            templateKey,
            setTemplateKey,
            updateBatchResponse,
            setUpdateBatchResponse,
            template,
            setTemplate
        } as MainContextType}>
            {children}
        </MainContext.Provider>
    )
}


const useMainContext = () => useContext(MainContext)

export { MainContextProvider, useMainContext, MainContextType }