export const emptyPage = [
    {
        "layers": {
            "ROOT": {
                "type": {
                    "resolvedName": "RootLayer"
                },
                "props": {
                    "boxSize": {
                        "width": 1600,
                        "height": 800
                    },
                    "position": {
                        "x": 0,
                        "y": 0
                    },
                    "rotate": 0,
                    "color": "rgb(255, 255, 255)",
                    "image": null
                },
                "locked": false,
                "child": [],
                "parent": null
            }
        }
    }
]