
function convertHtmlToText(htmlText, textData) {
    const parser = new DOMParser();

    const doc = parser.parseFromString(htmlText, 'text/html');
    const pTags = Array.from(doc.querySelectorAll('p'));

    // console.log("pTags", pTags)
    const newPTags = pTags?.map((pTag, index) => {
        const spantag = pTag.querySelector('span');
        // console.log("spantag", spantag)
        if (spantag) {
            spantag.innerText = textData[index];
        } else {
            pTag.innerText = textData[index];
        }
        return pTag;
    })

    // console.log("htmltext", htmlText)
    // console.log("newPTags", newPTags)
    return newPTags?.map((pTag) => pTag.outerHTML).join('')
}



function extractTextFromHTML(htmlText) {

    // Create a DOMParser
    const parser = new DOMParser();

    // Parse the HTML-like text
    const doc = parser.parseFromString(htmlText, 'text/html');

    // Get an array of <p> tags
    const paragraphTags = Array.from(doc.getElementsByTagName('p'));

    // Extract text content from each <p> tag
    const textArray = paragraphTags?.map(pTag => pTag.textContent.trim());

    return textArray;
}

export { convertHtmlToText, extractTextFromHTML }